import { Component } from '@angular/core';
import {  NbLogoutComponent } from '@nebular/auth';

@Component({
  selector: 'ngx-logout',
  templateUrl: './logout.component.html',
})
export class NbxLogoutComponent extends NbLogoutComponent {

  logout(strategy: string ) {
    localStorage.removeItem('auth_app_token');
    this.service.logout(strategy).subscribe(logout => {
      localStorage.removeItem('refreshToken');
      this.router.navigate(['auth/login']);
    });
  }
}
