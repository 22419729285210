import { Component} from '@angular/core';

@Component({
  selector: 'ngx-table-loader',
  templateUrl: './table-loader.component.html',
  styleUrls: ['./table-loader.component.scss'],
})
export class TableLoaderComponent  {  
  
}
