import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { NbAuthComponent } from '@nebular/auth';
import { NgxLoginComponent } from './components/login/login.component';
import { NbxLogoutComponent } from './components/logout/logout.component';
import { NbxRequestPasswordComponent } from './components/request-password/request-password.component';
import { NbxResetPasswordComponent } from './components/reset-password/reset-password.component';

export const routes: Routes = [
  {
    path: '',
    component: NbAuthComponent,
    children: [
      {
        path: 'login',
        component: NgxLoginComponent,
      },
      {
        path: 'logout',
        component: NbxLogoutComponent,
      },
      {
        path: 'request-password',
        component: NbxRequestPasswordComponent, 
      },
      {
        path: 'reset-password',
        component: NbxResetPasswordComponent, 
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class NgxAuthRoutingModule {
}
