import { Directive, TemplateRef } from '@angular/core';

@Directive({ selector: '[ngxSelectOption]' })
export class NgxSelectOptionDirective {
    constructor(public template: TemplateRef<any>) {
    }
}

@Directive({ selector: '[ngxSelectOptionSelected]' })
export class NgxSelectOptionSelectedDirective {
    constructor(public template: TemplateRef<any>) {
    }
}

@Directive({ selector: '[ngxSelectOptionNotFound]' })
export class NgxSelectOptionNotFoundDirective {
    constructor(public template: TemplateRef<any>) {
    }
}
