import { NgModule, ModuleWithProviders } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DatePipe } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NbCardModule, NbTreeGridModule, NbIconModule, NbButtonModule, NbInputModule, NbSelectModule, NbSpinnerModule } from '@nebular/theme';

import { TableSearchComponent } from './component/table-search/table-search.component';
import { TableLoaderComponent } from './component/table-loader/table-loader.component';
import { NgxSelectModule } from './component/ngx-select/ngx-select.module';
import { NgxSelectComponent } from './component/ngx-select/ngx-select.component';
import { CommonService } from './common.service';

@NgModule({
  declarations: [
    TableSearchComponent,
    TableLoaderComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    NbCardModule,
    NbTreeGridModule,
    NbIconModule,
    NbInputModule,
    NbSelectModule,
    NbButtonModule,
    NgxSelectModule,
    NbSpinnerModule,
  ],
  providers: [
    DatePipe,
    CommonService,
  ],
  exports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    NbCardModule,
    NbTreeGridModule,
    NbIconModule,
    NbInputModule,
    NbSelectModule,
    NgxSelectModule,
    NbButtonModule,
    TableSearchComponent,
    TableLoaderComponent,
    NgxSelectComponent,
    NbSpinnerModule,
  ],
})

export class SharedModule {
  static forRoot(): ModuleWithProviders {
    return {
      ngModule: SharedModule,
    };
  }
}
