import { Component, Input, OnChanges, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'ngx-table-search',
  templateUrl: './table-search.component.html',
  styleUrls: ['./table-search.component.scss'],
})
export class TableSearchComponent implements OnChanges {
  @Input() isLoadingSearch:any;
  @Input() isFloatRight:boolean=true;
  @Output() onSearch: EventEmitter<string> = new EventEmitter();
  public publickeyword: string = '';
  public typing: boolean = false;
  public typingTimeout: any = 0;
  public previousSearched: string = '';
  public previousSearchedText:string='';

  ngOnChanges() {
  }

  onKeydown(event) {
    const keyword = event.target.value;

    if(keyword==='' &&  this.previousSearched && this.previousSearched.length>=3) {
      this.onSearch.emit('');
    }
    this.previousSearchedText=keyword;
    if (keyword === '' || keyword === ' ')
      return null;

    if (keyword === this.previousSearched)
      return null;

    if (this.typingTimeout) {
      clearTimeout(this.typingTimeout);
    }
    this.typing = false;
    const self = this;
    this.typingTimeout = setTimeout(function () {
      if (keyword.trim().length >= 3) {
        self.previousSearched = keyword;
        self.isLoadingSearch = true;
        self.onSearch.emit(event.target.value);
      }
    }, 2000);

    
  }
}
