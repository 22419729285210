import { Component } from '@angular/core';
import {  NbResetPasswordComponent } from '@nebular/auth';

@Component({
  selector: 'ngx-reset-password',
  templateUrl: './reset-password.component.html',
})
export class NbxResetPasswordComponent extends NbResetPasswordComponent {
 
 
}
