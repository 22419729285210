import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';

import { NgxAuthRoutingModule } from './auth-routing.module';
import { NbAuthModule, NbPasswordAuthStrategy, NbAuthJWTToken } from '@nebular/auth';
import {
  NbAlertModule,
  NbButtonModule,
  NbCheckboxModule,
  NbInputModule,
} from '@nebular/theme';
import { NgxLoginComponent } from './components/login/login.component';
import { environment } from '../../environments/environment';
import { NbxLogoutComponent } from './components/logout/logout.component';
import { AuthComponent } from './components/auth.component';
import { NbxRequestPasswordComponent } from './components/request-password/request-password.component';
import { NbxResetPasswordComponent } from './components/reset-password/reset-password.component';


@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    RouterModule,
    NbAlertModule,
    NbInputModule,
    NbButtonModule,
    NbCheckboxModule,
    NgxAuthRoutingModule,
    NbAuthModule.forRoot({
      strategies: [
        NbPasswordAuthStrategy.setup({
          name: 'email',
          baseEndpoint: environment.apiUrl,
          login: {
            endpoint: '/Account/AdminLogin',
            method: 'post',
            redirect: {
              success: '/pages/dashboard',
              failure: null, // stay on the same page
            },
            alwaysFail: false,
            requireValidToken: false,
            defaultErrors: ['Invalid Login Details.'],
            defaultMessages: ['You have been successfully logged in.'],
          },
          token: {
            class: NbAuthJWTToken,
            key: 'data.access_token',
          },
          requestPass: {
            endpoint: '/Account/ForgotPasswordByEmail',
            method: 'post',
            redirect: {
              success: '/',
              failure: null,
            },
            defaultErrors: ['Something went wrong, please try again.'],
            defaultMessages: ['Reset password instructions have been sent to your email.'],
          },
          resetPass: {
            endpoint: '/Account/ResetPassword',
            method: 'put',
            redirect: {
              success: '/',
              failure: null,
            },
            resetPasswordTokenKey: 'code',
            defaultErrors: ['Something went wrong, please try again.'],
            defaultMessages: ['Your password has been successfully changed.'],
          },
          logout: {
            alwaysFail: false,
            endpoint: '/Account/Logout',
            method: 'delete',
            redirect: {
              success: '/',
              failure: null,
            },
            defaultErrors: ['Something went wrong, please try again.'],
            defaultMessages: ['You have been successfully logged out.'],
          },
          refreshToken: {
            endpoint: '/Account/RefreshToken',
            method: 'post',
            requireValidToken: false,
            redirect: {
              success: null,
              failure: null,
            },
            defaultErrors: ['Something went wrong, please try again.'],
            defaultMessages: ['Your token has been successfully refreshed.'],
          },
          errors: {
            key: 'errorMessage',
          },
        }), 
      ],
      forms: {
        login: {
          redirectDelay: 0,
          showMessages: {
            success: true,
            error: true,
          },
        },
        logout: {
          redirectDelay: 0,
        },
        requestPassword: {
          redirectDelay: 2000,
          showMessages: {
            success: true,
            error: true,
          },
        },
        resetPassword: {
          redirectDelay: 2000,
          showMessages: {
            success: true,
            error: true,
          },
        },
      },
    }),
  ],
  declarations: [
    NgxLoginComponent,
    NbxLogoutComponent,
    AuthComponent,
    NbxRequestPasswordComponent,
    NbxResetPasswordComponent,
  ],
})
export class NgxAuthModule {
}
